import { useEffect, useState } from 'react';
import { updateUserGuardrailSettings, UserStore } from 'stores/user';
import CheckboxSelection from 'ui/atoms/CheckboxSelection';
import { debounce } from 'lodash';

const debouncedUpdateGuardrailSettings = debounce((settings) => {
  updateUserGuardrailSettings(settings);
});

function GuardrailSettingsSection() {
  const guardrailSettings = UserStore.useState((s) => s?.guardrailSettings);
  const { phi, medical } = guardrailSettings || {};

  const [phiValue, setPhiValue] = useState(phi || false);
  const [medicalValue, setMedicalValue] = useState(medical || false);

  useEffect(() => {
    setPhiValue(phi || false);
    setMedicalValue(medical || false);
  }, [phi, medical]);

  useEffect(() => {
    if (phi !== phiValue || medical !== medicalValue) {
      debouncedUpdateGuardrailSettings({
        phi: phiValue,
        medical: medicalValue,
      });
    }
  }, [phiValue, medicalValue, phi, medical]);

  return (
    <div
      data-testid="guardrail-settings"
      className="settings-toggle-guardrails"
    >
      <CheckboxSelection
        value="guard1"
        label="PHI guardrails"
        setSelected={(e) => {
          setPhiValue(e.target.checked);
        }}
        isChecked={phiValue}
      />
      <CheckboxSelection
        value="guard2"
        label="Medical advice guardrails"
        setSelected={(e) => {
          setMedicalValue(e.target.checked);
        }}
        isChecked={medicalValue}
      />
    </div>
  );
}

export default GuardrailSettingsSection;
