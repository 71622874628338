import { CurrentConversationStore } from 'stores/current-conversation';
import { differenceInMinutes } from 'date-fns';

export type FileType = {
  datetimeCreated: string;
  datetimeLastUpdated: string;
  displayFileName: string;
  id: string;
  originalFileUrl: string;
  privacyLevel: string;
  processedFileUrl: string;
  processingFailureComment: string;
  processingStatus: string;
  userId: string;
  tokenCount?: number;
  sizeInBytes?: number;
};

export type ConversationFileType = FileType & {
  isSelected: boolean;
};

export type IndexType = {
  datetimeCreated: string;
  datetimeLastUpdated: string;
  description: string;
  displayName: string;
  id: string;
  numFiles: number;
  numWebsites: number;
  status: string;
};

export type ConversationIndexType = IndexType & {
  isSelected: boolean;
};

export type ResourceSelectionType = {
  id: string;
  displayFileName: string;
  isSelected?: boolean;
  isUsedInConversation?: boolean;
  datetimeCreated: string;
  datetimeLastUpdated: string;
  processingStatus: string;
  processingFailureComment: string;
  sublabel: string;
  itemType: 'file' | 'index';
  description?: string;
  [key: string]: any; // Allow arbitrary properties
};

export const PROCESSING_TIMEOUT_IN_MINUTES = 5;

function isFileInProgress(file) {
  if (file.processingStatus !== 'in progress') {
    return false;
  }

  const now = new Date();
  const minutesDifference = differenceInMinutes(
    now,
    new Date(file.datetimeLastUpdated),
  );

  return (
    file.processingStatus === 'in progress' &&
    minutesDifference < PROCESSING_TIMEOUT_IN_MINUTES
  );
}

export const hasFilesInProgress = (files: FileType[]): boolean => {
  return files.some(isFileInProgress);
};

export const filterFilesInProgress = (files: FileType[]): FileType[] => {
  return files.filter(isFileInProgress);
};

export const getStuckFilesInProgress = (files) => {
  return files.filter((file) => {
    if (file.processingStatus !== 'in progress') {
      return false;
    }

    const now = new Date();
    const minutesDifference = differenceInMinutes(
      now,
      new Date(file.datetimeLastUpdated),
    );

    return (
      file.processingStatus === 'in progress' &&
      minutesDifference > PROCESSING_TIMEOUT_IN_MINUTES
    );
  });
};

export const mapFileIdToFile = (
  fileId: string,
  files: FileType[],
): FileType | {} => {
  return files.find((file) => file.id === fileId) || {};
};

export const diffCurrentPendingFiles = (
  personalFiles: (FileType | IndexType)[],
  fileContexts: string[],
  isIndex = false,
): (FileType | IndexType)[] => {
  const propertyNames = isIndex
    ? 'associatedIndexIds'
    : 'associatedFileRecordIds';
  const messages = CurrentConversationStore.getRawState().messages;

  const uniqFilesAlreadyApplied = new Set(
    messages.map((message) => message[propertyNames]).flat(),
  );

  const pendingFileIds = fileContexts.filter(
    (id) => !uniqFilesAlreadyApplied.has(id),
  );

  return personalFiles.filter((file) => pendingFileIds.includes(file.id));
};

export const getUniqueSelectedFileContextIdsFromMessages = (
  messages: any[],
  propertyName = 'associatedFileRecordIds',
) => {
  const selectedFileIds = (messages as any).reduce((acc, m) => {
    if (m[propertyName]) {
      return [...acc, ...m[propertyName]];
    }
    return acc;
  }, []);
  return Array.from(new Set(selectedFileIds));
};

export const sortFilesByLastUpdated = (files: FileType[]) => {
  return files.sort(
    (a, b) =>
      new Date(a.datetimeLastUpdated).getTime() -
      new Date(b.datetimeLastUpdated).getTime(),
  );
};

export const filterValidOnlyFileContexts = (
  files: any[],
  fileContexts: any[],
) => {
  const filteredFiles = getFilesByContexts(files, fileContexts);

  return filteredFiles.filter((f) => !f.processingFailureComment);
};

export const sanitizeFileContexts = (
  files: any[] = [],
  fileContexts: string[] = [],
): string[] => {
  const filteredFiles = getFilesByContexts(files, fileContexts);

  return filteredFiles
    .filter((f) => f.processingStatus === 'done')
    .filter((f) => !f.processingFailureComment)
    .map((f) => f.id);
};

export const getFilesByContexts = (
  files: FileType[] = [],
  contexts: string[] = [],
) => {
  let filterableFiles: FileType[] = files;

  if (!files.length) {
    filterableFiles = CurrentConversationStore.getRawState().fileContextFiles;
  }

  return filterableFiles.filter((file) => contexts.includes(file.id));
};

export const formatFileErrorMessages = (
  error: string,
  type = 'chit',
  metadata = { name: '' },
) => {
  if (!error) {
    return 'Error processing';
  }

  const errorMapping = {
    'Error occured while processing': {
      chit: 'Error processing',
      list: 'Error processing file',
      toast: `${metadata.name} could not be processed. It won’t be applied to your file context.`,
    },
    'Error occurred while processing file': {
      chit: 'Error processing',
      list: 'Error processing file',
      toast: `${metadata.name} could not be processed. It won’t be applied to your file context.`,
    },
    'File rejected: Security violation': {
      chit: 'Malicious file',
      list: 'Malicious file',
      toast: `${metadata.name} was found to be malicious. It won’t be applied to your file context.`,
    },
    'File max token length exceeded': {
      chit: 'Too much text',
      list: 'File has too much text',
      toast: `${metadata.name} contains too much text. It won’t be applied to your file context.`,
    },
    'File contains too much text': {
      chit: 'Too much text',
      list: 'File has too much text',
      toast: `${metadata.name} contains too much text. It won’t be applied to your file context.`,
    },
  };

  const hasErrorMap = errorMapping[error];

  if (hasErrorMap) {
    return errorMapping[error][type];
  }

  return error;
};

export function formatFileTypeExtensions(filename: string): [string, string] {
  const fileTypeMapping: { [key: string]: string } = {
    pptx: 'Powerpoint presentation',
    docx: 'Word document',
    txt: 'Text file',
    pdf: 'PDF',
    xlsx: 'Excel spreadsheet',
  };

  const fileExtension = filename?.split('.').pop()?.toLowerCase();

  if (fileExtension && fileTypeMapping[fileExtension]) {
    return [fileTypeMapping[fileExtension], fileExtension];
  } else {
    return ['Unknown file', 'unknown'];
  }
}

export function formatFileSize(fileSize: string | number) {
  const bytes = Number(fileSize);

  if (!bytes) {
    return 'Unknown file size';
  }

  const MB = 1024 * 1024;
  const KB = 1024;

  if (bytes >= MB) {
    return Math.round(bytes / MB) + ' MB';
  } else if (bytes >= KB) {
    return Math.round(bytes / KB) + ' KB';
  } else {
    return bytes + ' bytes';
  }
}
