import { Tag } from 'ui/atoms';
import { MarkdownRenderer } from 'ui/molecules/MarkdownRenderer';
import PHIProtection from '../PHIProtection';

import './conversation-response-row.css';
import ConversationResponseSources from '../ConversationResponseSources';

const ConversationResponseRow = ({
  message,
  profileImg,
  isError = false,
  allowRetry = false,
  isGuardRailed = false,
  guardRailType = 'default',
  userConfirmedPHIAllowed = false,
  sources = null,
}) => {
  const canDisplayMessage = !isGuardRailed;

  return (
    <div className="message">
      <div className={`response${isError ? ' error' : ''}`}>
        <img src={profileImg} alt="profile-pic" />

        {isGuardRailed && <PHIProtection type={guardRailType} />}

        {canDisplayMessage && (
          <>
            {!isError && (
              <div className="generated-response-row">
                {userConfirmedPHIAllowed && (
                  <Tag>Generated using prompt that may contain PHI</Tag>
                )}
                <MarkdownRenderer text={message} />
                {sources && <ConversationResponseSources sources={sources} />}
              </div>
            )}
            {isError && (
              <span className="error-message">
                We're having a little trouble generating a response.{' '}
                {allowRetry && (
                  <span>Would you like to try regenerating that response?</span>
                )}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ConversationResponseRow;
