import { useEffect } from 'react';
import { Button } from 'ui/atoms';
import { openPublicFile } from 'utils/open-public-file';
import { loadConversationHistory } from 'stores/conversation-history';

import './phi-protection.css';

const PHIProtection = ({ type = 'default' }) => {
  useEffect(() => {
    loadConversationHistory();
  }, []);
  return (
    <div className="phi-protection">
      {type === 'default' || (type === 'phi' && <PHIDefault />)}

      {type === 'medical_advice' && <PHIMedicalAdvice />}

      {type === 'jailbreak' && <PHIJailbreak />}
    </div>
  );
};

export default PHIProtection;

const PHIDefault = () => {
  return (
    <>
      <h3>Personal health information (PHI) may by present in your prompt.</h3>
      PHI is not permitted in Goldie.
    </>
  );
};

const PHIMedicalAdvice = () => {
  return (
    <>
      <h3>It seems like you&#39;re asking for medical advice</h3>
      Asking for medical advice is not permitted in Goldie.
    </>
  );
};

const PHIJailbreak = () => {
  return (
    <>
      <h3>It seems like you&#39;re trying to jailbreak Goldie.</h3>
      Jailbreaking is strictly against Goldie&#39;s&nbsp;
      <Button
        type="text"
        className="button-inline-link no-color"
        onClick={() => openPublicFile('goldie-terms-of-use.pdf')}
      >
        terms of use.
      </Button>
    </>
  );
};
